import { type PropsWithChildren } from 'react';
import { Loader } from '@module/mdrt-org/shared/components';

type LoaderWrapperProps = PropsWithChildren<{
  error: Error | null;
  isLoading: boolean;
}>;

export const LoaderWrapper = ({ children, error, isLoading }: LoaderWrapperProps) => {
  if (error) {
    return <h1>{error.message}</h1>;
  }

  return (
    <>
      {isLoading && <Loader />}
      {children}
    </>
  );
};
