'use client';

import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { type FormEvent, useRef, useState } from 'react';

import { LoaderWrapper } from '@module/mdrt-org/admin-dashboard/components';
import { type UsersListTableContent } from '@module/mdrt-org/admin-dashboard/components/users-list-table/get-users-list-table-content';
import { Button, Pagination } from '@shared/ui-components';
import { INITIAL_PAGE_NUMBER } from '@shared/utils';

import { AdminDashboardDataTag, getUserAbnormalities } from '../../utils/data';

import styles from './users-list-table.module.scss';

export const UsersListTable = ({
  adminDashboardPath,
  cmsContent,
}: {
  adminDashboardPath: string;
  cmsContent: UsersListTableContent;
}) => {
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE_NUMBER);
  const inputRef = useRef<HTMLInputElement>(null);

  const router = useRouter();

  const {
    data: usersAbnormalitiesData,
    error: getUsersAbnormalitiesDataError,
    isLoading: isUsersAbnormalitiesDataFetching,
  } = useQuery({
    queryFn: () =>
      getUserAbnormalities({
        page: currentPage,
      }),
    queryKey: [AdminDashboardDataTag.GET_USER_ABNORMALITIES, currentPage],
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleNavigation = (personId: number | string) => {
    const href = `${adminDashboardPath}/${personId}`;

    router.push(href, { scroll: true });
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    const inputValue = inputRef.current?.value;

    if (inputValue) {
      handleNavigation(inputValue);
    }
  };

  return (
    <section className="table-section">
      <form onSubmit={handleSubmit}>
        <div className={styles['table-actions']}>
          <input
            className={styles['table-actions__input']}
            placeholder={cmsContent.usersRequiringAttentionEnterPersonId}
            ref={inputRef}
            type="number"
          />
          <Button type="submit">{cmsContent.usersRequiringAttentionGoToPage}</Button>
        </div>
      </form>
      <LoaderWrapper
        error={getUsersAbnormalitiesDataError}
        isLoading={isUsersAbnormalitiesDataFetching}
      >
        <div className="table-wrapper">
          <table className="table">
            <thead className="table__header">
              <tr>
                <td>{cmsContent.usersRequiringAttentionId}</td>
                <td>{cmsContent.usersRequiringAttentionName}</td>
                <td>{cmsContent.usersRequiringAttentionEmailAddress}</td>
                <td>{cmsContent.usersRequiringAttentionDescription}</td>
                <td>{cmsContent.usersRequiringAttentionDeadLetterCount}</td>
              </tr>
            </thead>
            <tbody className="table__body">
              {usersAbnormalitiesData && usersAbnormalitiesData.results?.length > 0 ? (
                usersAbnormalitiesData.results.map((item) => (
                  <tr
                    className="row__clickable"
                    key={item.personId}
                    onClick={() => handleNavigation(item.personId)}
                  >
                    <td>{item.personId}</td>
                    <td>{item.name}</td>
                    <td>{item.emailAddress}</td>
                    <td>{item.abnormalityDescriptions.join(', ')}</td>
                    <td>{item.deadLetterCount}</td>
                  </tr>
                ))
              ) : (
                <tr className="table__no-results">
                  <td>{cmsContent.usersRequiringAttentionNoResults}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </LoaderWrapper>
      {usersAbnormalitiesData?.count ? (
        <Pagination
          initialPage={currentPage}
          onPageChange={handlePageChange}
          totalItemsCount={usersAbnormalitiesData?.count}
        />
      ) : null}
    </section>
  );
};
