'use client';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import { LoaderWrapper } from '@module/mdrt-org/admin-dashboard/components';
import { type PersonScopedDataTableContent } from '@module/mdrt-org/admin-dashboard/components/person-scoped-data-table/get-person-scoped-data-table-content';
import {
  getInboundSyncEvents,
  getPersonScopedData,
  postCommunicationPreferencesSync,
  postCustomerProfileSync,
  postMembershipApplicationSync,
  postSetExcludeFromLateFee,
} from '@module/mdrt-org/admin-dashboard/utils';
import { Button, Checkbox } from '@shared/ui-components';
import { INITIAL_PAGE_NUMBER } from '@shared/utils';

import { AdminDashboardDataTag } from '../../utils/data';
import { postPrePaymentSync } from '../../utils/data/post-pre-payment-sync';
import { postProductionSync } from '../../utils/data/post-production-sync';
import { ExpandableTableRow } from '../expandable-table-row';

import styles from './person-scoped-data-table.module.scss';

export const PersonScopedDataTable = ({
  cmsContent,
  personId,
}: {
  cmsContent: PersonScopedDataTableContent;
  personId: number;
}) => {
  const {
    data: customerProfileData,
    error: getCustomerProfileDataError,
    isFetching: isGetCustomerProfileDataFetching,
    refetch: refetchGetCustomerProfileData,
  } = useQuery({
    enabled: false,
    queryFn: () => getPersonScopedData({ personId }),
    queryKey: [AdminDashboardDataTag.GET_CUSTOMER_PROFILE_DATA, personId],
  });

  const { isFetching: isPostCustomerDataFetching, refetch: refetchPostCustomerData } = useQuery({
    enabled: false,
    queryFn: () => postCustomerProfileSync({ personId }),
    queryKey: [AdminDashboardDataTag.POST_CUSTOMER_PROFILE_DATA_SYNC],
  });

  const { isFetching: isPostOnDemandProductionFetching, refetch: refetchPostOnDemandProduction } =
    useQuery({
      enabled: false,
      queryFn: () => postProductionSync({ personId }),
      queryKey: [AdminDashboardDataTag.POST_PRODUCTION_SYNC],
    });

  const { isFetching: isPostPrePaymentFetching, refetch: refetchPostPrePayment } = useQuery({
    enabled: false,
    queryFn: () => postPrePaymentSync({ personId }),
    queryKey: [AdminDashboardDataTag.POST_PRE_PAYMENT_SYNC],
  });

  const {
    isFetching: isPostCommunicationPreferencesFetching,
    refetch: refetchPostCommunicationPreferences,
  } = useQuery({
    enabled: false,
    queryFn: () => postCommunicationPreferencesSync({ personId }),
    queryKey: [AdminDashboardDataTag.POST_COMMUNICATION_PREFERENCES_SYNC],
  });

  const {
    isFetching: isPostMembershipApplicationFetching,
    refetch: refetchPostPostMembershipApplication,
  } = useQuery({
    enabled: false,
    queryFn: () => postMembershipApplicationSync({ personId }),
    queryKey: [AdminDashboardDataTag.POST_MEMBERSHIP_APPLICATION_SYNC],
  });

  const { isFetching: isGetSyncEventsDataFetching, refetch: refetchGetSyncEventsDataData } =
    useQuery({
      enabled: false,
      queryFn: () => getInboundSyncEvents({ page: INITIAL_PAGE_NUMBER, personId }),
      queryKey: [AdminDashboardDataTag.GET_SYNC_DATA, null, INITIAL_PAGE_NUMBER],
    });

  const { isFetching: isPostExcludedFromLateFeeFetching, refetch: refetchPostExcludedFromLateFee } =
    useQuery({
      enabled: false,
      queryFn: () =>
        postSetExcludeFromLateFee({
          excludedFromLateFee: !customerProfileData?.customerProfile?.isExcludedFromLateFee,
          personId,
        }),
      queryKey: [AdminDashboardDataTag.POST_EXCLUDED_FROM_LATE_FEE],
    });

  const handlePostCustomerProfileDataRetry = async () => {
    await refetchPostCustomerData();
    await refetchPostOnDemandProduction();
    await refetchPostPrePayment();
    await refetchPostCommunicationPreferences();
    await refetchPostPostMembershipApplication();
    await refetchGetCustomerProfileData();
    await refetchGetSyncEventsDataData();
  };

  const toggleSetExcludeLateFee = async () => {
    await refetchPostExcludedFromLateFee();
    await refetchGetCustomerProfileData();
  };

  const tableData = [
    {
      data: customerProfileData?.customerProfile,
      label: cmsContent.personDataCustomerProfile,
    },
    {
      data: customerProfileData?.membershipApplication,
      label: cmsContent.personDataMembershipApplication,
    },
    {
      data: customerProfileData?.productions,
      label: cmsContent.personDataProductions,
    },
    {
      data: customerProfileData?.payments,
      label: cmsContent.personDataPayments,
    },
    {
      data: customerProfileData?.prePayments,
      label: cmsContent.personDataPrepayments,
    },
  ];

  const isLoading =
    isGetCustomerProfileDataFetching ||
    isPostCustomerDataFetching ||
    isPostOnDemandProductionFetching ||
    isPostPrePaymentFetching ||
    isPostCommunicationPreferencesFetching ||
    isPostMembershipApplicationFetching ||
    isGetSyncEventsDataFetching ||
    isPostExcludedFromLateFeeFetching;

  return (
    <section>
      <LoaderWrapper error={getCustomerProfileDataError} isLoading={isLoading}>
        <div className={styles['person-scoped-section-header']}>
          <h2>{cmsContent.personsData}</h2>
          <Button
            className={styles['person-scoped-section-header__button']}
            onClick={handlePostCustomerProfileDataRetry}
          >
            {cmsContent.personDataTriggerSync}
          </Button>
        </div>
        <div className={styles['person-scoped-section-header__actions']}>
          <p>{cmsContent.personDataExcludeFromLateFee}</p>
          <Checkbox
            checked={customerProfileData?.customerProfile?.isExcludedFromLateFee}
            onChange={toggleSetExcludeLateFee}
          />
        </div>
        <div className="table-section">
          <div className="table-wrapper">
            <table className={classNames('table', styles['person-data-table'])}>
              <tbody className="table__body">
                {tableData.map((item) => {
                  return (
                    <ExpandableTableRow
                      expandableData={item.data}
                      key={item.label}
                      noResultsLabel={cmsContent.personDataNoResults}
                    >
                      <td>{item.label}</td>
                    </ExpandableTableRow>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </LoaderWrapper>
    </section>
  );
};
