'use client';

import { useQuery } from '@tanstack/react-query';

import { LoaderWrapper } from '@module/mdrt-org/admin-dashboard/components';
import { type DeadLettersSectionContent } from '@module/mdrt-org/admin-dashboard/components/dead-letters-section/get-dead-letters-section-content';
import { DeadLettersTable } from '@module/mdrt-org/admin-dashboard/components/dead-letters-table';
import { postRetryDeadLetters } from '@module/mdrt-org/admin-dashboard/utils';
import { Button } from '@shared/ui-components';

import { AdminDashboardDataTag } from '../../utils/data/constants/tags';
import { getDeadLetters } from '../../utils/data/get-dead-letters';

import styles from './dead-letters-section.module.scss';

export const DeadLettersSection = ({
  cmsContent,
  personId,
}: {
  cmsContent: DeadLettersSectionContent;
  personId: number;
}) => {
  const {
    data: deadLettersData,
    error: getDeadLettersError,
    isFetching: isGetDeadLettersFetching,
    refetch: refetchGetDeadLetters,
  } = useQuery({
    enabled: false,
    queryFn: () => getDeadLetters({ personId }),
    queryKey: [AdminDashboardDataTag.DEAD_LETTER_QUEUE, personId],
  });

  const { isFetching: isPostRetryDeadLettersFetching, refetch: refetchPostRetryDeadLetters } =
    useQuery({
      enabled: false,
      queryFn: () => postRetryDeadLetters({ personId }),
      queryKey: [AdminDashboardDataTag.POST_RETRY_DEAD_LETTERS],
    });

  const handleDeadLettersRetry = async () => {
    await refetchPostRetryDeadLetters();
    await refetchGetDeadLetters();
  };

  return (
    <LoaderWrapper
      error={getDeadLettersError}
      isLoading={isGetDeadLettersFetching || isPostRetryDeadLettersFetching}
    >
      <section className={styles['dead-letters-section']}>
        <div className={styles['dead-letters-section-header']}>
          <h2 className={styles['dead-letters-section-header__heading']}>
            {cmsContent.deadLettersSectionContent.deadLettersQueue}
          </h2>
          <form
            action={handleDeadLettersRetry}
            className={styles['dead-letters-section-header__actions']}
          >
            <Button className={styles['dead-letters-section-header__button']} type="submit">
              {cmsContent.deadLettersSectionContent.deadLettersRetryDeadLetters}
            </Button>
          </form>
        </div>
        <div className={styles['dead-letters-section__tables-wrapper']}>
          {deadLettersData?.length ? (
            deadLettersData.map((deadLettersTableData) => (
              <DeadLettersTable
                cmsContent={cmsContent.deadLettersTableContent}
                key={deadLettersTableData.messageName}
                tableData={deadLettersTableData}
              />
            ))
          ) : (
            <div>{cmsContent.deadLettersSectionContent.deadLettersNoDeadLetters}</div>
          )}
        </div>
      </section>
    </LoaderWrapper>
  );
};
